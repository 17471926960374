.bg-card {
    background-position: right;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    overflow: hidden;
}

.bg-holder {

    /* padding-left: 5px;
    margin-left: -5px;

    padding-top: 5px;
    margin-top: 10px;

    padding-bottom: 5px;
    margin-bottom: 10px; */

    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: contain;
    /* background-position: center; */
    /* overflow: hidden; */
    will-change: transform, opacity, filter;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
}

/* .wrapper {
    position: relative;
    width: 280px;
    height: 200px;
    background-size: cover;
    overflow: hidden;
  } */